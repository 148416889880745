/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import classNames from 'classnames';
import { useSpring, animated } from 'react-spring';

import Layout from '../components/layout';
import SEO from '../components/seo';

import './faq.scss';

const Data = [
  { id: 0, title: 'When does the program start and end?', description: 'The 4 week program takes rolling admissions and We can be flexible on the dates if you need to start sooner or later. The 1hr weekly call will be scheduled for a consistent day and time.' },
  { id: 1, title: 'What is the format?', description: 'The 4 week program takes rolling admissions and We can be flexible on the dates if you need to start sooner or later. The 1hr weekly call will be scheduled for a consistent day and time.' },
  { id: 2, title: 'What is the cost?', description: 'The 4 week program takes rolling admissions and We can be flexible on the dates if you need to start sooner or later. The 1hr weekly call will be scheduled for a consistent day and time.' },
  { id: 3, title: 'Any discounts?', description: 'The 4 week program takes rolling admissions and We can be flexible on the dates if you need to start sooner or later. The 1hr weekly call will be scheduled for a consistent day and time.' },
  { id: 4, title: 'What tools do you use in the program?', description: 'The 4 week program takes rolling admissions and We can be flexible on the dates if you need to start sooner or later. The 1hr weekly call will be scheduled for a consistent day and time.' },
  { id: 5, title: 'What if I want to keep working with the team after the program is over?', description: 'The 4 week program takes rolling admissions and We can be flexible on the dates if you need to start sooner or later. The 1hr weekly call will be scheduled for a consistent day and time.' },
  { id: 6, title: 'What is the application process?', description: 'The 4 week program takes rolling admissions and We can be flexible on the dates if you need to start sooner or later. The 1hr weekly call will be scheduled for a consistent day and time.' },
  { id: 7, title: 'What are you looking for in applications?', description: 'The 4 week program takes rolling admissions and We can be flexible on the dates if you need to start sooner or later. The 1hr weekly call will be scheduled for a consistent day and time.' },
  { id: 8, title: 'When do I know if I’ve been selected?', description: 'The 4 week program takes rolling admissions and We can be flexible on the dates if you need to start sooner or later. The 1hr weekly call will be scheduled for a consistent day and time.' },
  { id: 9, title: 'If I apply now and change my mind, am I obligated to pay the program fee?', description: 'The 4 week program takes rolling admissions and We can be flexible on the dates if you need to start sooner or later. The 1hr weekly call will be scheduled for a consistent day and time.' },
];

const FAQ = () => {
  const [currentItem, selectItem] = useState(0);
  const fade = (index = 0) => useSpring({
    opacity: 1, delay: index * 400, transform: 'translateY(0)', from: { opacity: 0, transform: 'translateY(100px)' }, config: { duration: 400 },
  });
  const detailStyle = (id) => (currentItem === id
    ? useSpring({
      opacity: 1, height: 'auto', from: { opacity: 0, height: 0 }, config: { duration: 600 },
    })
    : useSpring({
      opacity: 0, height: 0, from: { opacity: 1, height: 'auto' }, config: { duration: 300 },
    }));

  return (
    <Layout extra={false} hasFooter={false}>
      <SEO title="FAQ" />
      <div className="FAQ">
        <div className="FAQ__Title">FAQ</div>
        <div className="FAQ__List">
          {Data.map((item, index) => (
            <animated.div style={fade(index)} className="FAQ__List__Item" key={`FAQ_${index}`}>
              <div onClick={() => selectItem(index)} className={classNames('FAQ__List__Item__Title', { 'FAQ__List__Item__Title-active': currentItem === item.id })}>{item.title}</div>
              <animated.div style={detailStyle(item.id)} className="FAQ__List__Item__Des">{item.description}</animated.div>
            </animated.div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default FAQ;
